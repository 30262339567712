interface IErrorCodes {
    [details: string] : string;
} 

const errorCodes:IErrorCodes = {
    "ERR_SYSTEM": "Si è verificato un errore, si prega di riprovare.",
    "ERR_WRONG_LOGIN": "Credenziali non riconosciute.",
    "ERR_UNAUTHORIZED": "Utente non autorizzato"
}

const getError = (errorCode?:string) => {
    if(!errorCode) {
        return errorCodes.ERR_SYSTEM
    }
    return errorCodes[errorCode] ?? errorCodes.ERR_SYSTEM
}

export default getError