import * as React from "react"
import {Redirect, Route, RouteComponentProps, RouteProps} from "react-router-dom"

type RouteComponent = React.FunctionComponent<RouteComponentProps<{}>> | React.ComponentClass<any>

const ProtectedRoute: React.FunctionComponent<RouteProps> = ({component:Comp, ...rest}) => {
  const renderFn = (C?: RouteComponent) => (props: RouteComponentProps) => {
    if (!C) {
      return null
    }

    const AUTHENTICATED = (!!localStorage.getItem("accessToken"))
    
    if (AUTHENTICATED) {
      return <C {...props} />
    }

    const redirectProps = {
      to: {
        pathname: "/login",
        state: {from: props.location},
      },
    }

    return <Redirect {...redirectProps} />
  }

  return <Route {...rest} render={renderFn(Comp)} />
}

export default ProtectedRoute