import React, { useEffect, useReducer, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import { RouteComponentProps } from 'react-router-dom'
import Header from 'src/Components/Common/Header'
import { CHANGE_FORM_VALUE, SET_OPENING_HOURS, LOAD_EDIT_PLACE, CHANGE_FORM_VALUE_TRANSLATION } from 'src/ActionTypes/Places'
import { Action, State, Translations } from 'src/Interfaces/Places'
import { toBase64 } from 'src/Utils/Images'
import { Category, Days, Parts, Sides } from 'src/Interfaces/Commons'
import { Link } from "react-router-dom"
import { HOURS_DEFAULT_MOR_FROM, HOURS_DEFAULT_MOR_TO, HOURS_DEFAULT_AFT_FROM, HOURS_DEFAULT_AFT_TO } from 'src/Utils/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ProtectedAPI } from 'src/Api/Common'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactTooltip from 'react-tooltip'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import Select from 'react-select'
import RichContent from '../Common/RichContent'
import parser from 'html-react-parser'


const ReactSwal = withReactContent(Swal)

type MatchParams = {
    id: string
}

const emptyTranslations = {
    it: "",
    en: "",
    de: "",
    fr: ""
}

const Place = (props:RouteComponentProps<MatchParams>)=>{
    
    const { id } = props?.match?.params
    
    const initialState:State = {
        name: "",
        name_translations: emptyTranslations,
        period: "",
        period_translations: emptyTranslations,
        description: "",
        description_translations: emptyTranslations,
        street: "",
        zipCode: "",
        city: "",
        lat: "",
        lng: "",
        photo: undefined,
        gallery: [],
        links: [],
        categories: [],
        placeStatus: "",
        openingHoursText: "",
        openingHoursText_translations: emptyTranslations,
        openingHours: {
            mon: {
                mor: {from:"08:00", to:"14:00"},
                aft: {from:"14:00", to:"18:00"}
            },
            tus: {
                mor: {from:"08:00", to:"14:00"},
                aft: {from:"14:00", to:"18:00"}
            },
            wed: {
                mor: {from:"08:00", to:"14:00"},
                aft: {from:"14:00", to:"18:00"}
            },
            thu: {
                mor: {from:"08:00", to:"14:00"},
                aft: {from:"14:00", to:"18:00"}
            },
            fri: {
                mor: {from:"08:00", to:"14:00"},
                aft: {from:"14:00", to:"18:00"}
            },
            sat: {
                mor: {from:"08:00", to:"14:00"},
                aft: {from:"14:00", to:"18:00"}
            },
            sun: {
                mor: {from:"08:00", to:"14:00"},
                aft: {from:"14:00", to:"18:00"}
            },
        },
        saltoIqId: "",
        saltoLockId: "",
        QRCodeLink: "",
        donateLink: "",
        infoDescription: "",
        infoDescription_translations: emptyTranslations,
        infoPhone: "",
        infoEmail: "",
        openingInstructions: "",
        openingInstructions_translations: emptyTranslations,
        accessProblems: "",
        accessProblems_translations: emptyTranslations,
        closingInstructions: "",
        closingInstructions_translations: emptyTranslations,
        closingProblems: "",
        closingProblems_translations: emptyTranslations,
    }

    const reducer = (state=initialState, action:Action) =>{
        switch(action.type) {
            case CHANGE_FORM_VALUE:
                const { formId, formValue } = action.payload
                if(!formId) return state;
                return {...state, [formId]: formValue}
            case CHANGE_FORM_VALUE_TRANSLATION:
                const { lang, formId:formIdTranslations, formValue:formValueTranslations} = action.payload
                const toBeUpdated = structuredClone(state[formIdTranslations!])
                toBeUpdated[lang!] = formValueTranslations
                return { ...state, [formIdTranslations!]: toBeUpdated}
            case SET_OPENING_HOURS:
                const { dayOfWeek, part, side, hour } = action.payload
                if(!dayOfWeek || !part || !side) return state
                const openingHours = state.openingHours
                openingHours[dayOfWeek][part][side] = hour
                return {...state, openingHours }
            case LOAD_EDIT_PLACE:
                return {...state, ...action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const [pageStatus, setPageStatus] = useState("IDLE")
    const [newPhotoStatus, setNewPhotoStatus] = useState("")
    const [newPhotoModalShow, setNewPhotoModalShow] = useState(false)
    const [newPhotoDescription, setNewPhotoDescription] = useState("")
    const [newPhotoDescriptionTranslations, setNewPhotoDescriptionTranslations] = useState<Translations>({it: "", en: "", de: "", fr: ""})
    const [newPhotoBase64, setNewPhotoBase64] = useState("")
    const [availableCategories, setAvailableCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [newLinkLabel, setNewLinkLabel] = useState("")
    const [newLinkUrl, setNewLinkUrl] = useState("")
    const [updatePhotoGalleryId, setUpdatePhotoGalleryId] = useState<string|undefined>(undefined)

    const getPlace = async (id:string) =>{
        const response = await ProtectedAPI.get(`places/${id}`)
        const categories = await ProtectedAPI.get('utils/categories')
        const _availableCategories = categories.data?.categories ?? []
        setAvailableCategories(_availableCategories.map((category: Category)=>{
            return {value: category._id, label: category.it}
        }))

        if(response.data.status==="ok") {
            const payload = {...response.data.place}
            const placeCategories = payload.categories?.map((category:string)=>{
                const _category =  _availableCategories.find((availableCategory:Category)=>availableCategory._id === category)
                if(!_category) {
                    return null
                }
                return {
                    value: _category._id,
                    label: _category.it
                }
            }).filter((category:any)=>!!category)
            setSelectedCategories(placeCategories)
            dispatch({type: LOAD_EDIT_PLACE, payload})
        }

    }

    useEffect(()=>{
        getPlace(id)
    }, [id])


    const onChangeMainPhoto = async (e:any)=>{
        setPageStatus("ELABORATING_PHOTO")
        try {
            const base64 = await toBase64(e.target.files[0])
            e.target.value=""
            const response = await ProtectedAPI.put(`places/${id}/uploadPhoto`, {position:'main', base64})
            setPageStatus("IDLE")
            if(response.data.status!=="ok") {
                return await ReactSwal.fire({
                    text: `Si è verificato un errore nel caricamento della foto.`,
                    icon: "error",
                })
            }
            return dispatch({type: CHANGE_FORM_VALUE, payload:{formId: 'photo', formValue:response.data.photo}})
        } catch(e) {
            setPageStatus("IDLE")
            await ReactSwal.fire({
                text: `Si è verificato un errore nel caricamento della foto.`,
                icon: "error",
            })
        }

    }

    const onChangeNewPhoto = async(e:any) =>{
        try {
            const base64 = await toBase64(e.target.files[0])
            e.target.value=""
            if(!base64) {
                return await ReactSwal.fire({
                    text: `Si è verificato un errore nell'elaborazione della foto.`,
                    icon: "error",
                })
            }
            setNewPhotoBase64(base64 as string)
        } catch(e) {
            await ReactSwal.fire({
                text: `Si è verificato un errore nell'elaborazione della foto.`,
                icon: "error",
            })
        }
    }

    const uploadGalleryPhoto = async() =>{
        setNewPhotoStatus("UPLOADING")
        try {
            const response = await ProtectedAPI.put(`places/${id}/uploadPhoto`, {base64: newPhotoBase64, description: newPhotoDescription, translations: newPhotoDescriptionTranslations, photoGalleryId: updatePhotoGalleryId})
            setNewPhotoStatus("")
            if(response.data.status!=="ok") {
                return await ReactSwal.fire({
                    text: `Si è verificato un errore nell'operazione.`,
                    icon: "error",
                })
            }
            setNewPhotoStatus("")
            setNewPhotoDescription("")
            setNewPhotoBase64("")
            setNewPhotoDescriptionTranslations({it: "", en: "", de: "", fr: ""})
            setUpdatePhotoGalleryId(undefined)
            setNewPhotoModalShow(false)
            return dispatch({type: CHANGE_FORM_VALUE, payload:{formId: 'gallery', formValue:response.data.gallery}})
        } catch(e) {
            setNewPhotoStatus("")
            setNewPhotoModalShow(false)
            await ReactSwal.fire({
                text: `Si è verificato un errore nell'operazione.`,
                icon: "error",
            })
        }
    } 

    const addPhotoToGallery = ()=>{
        setNewPhotoStatus("")
        setNewPhotoDescription("")
        setNewPhotoBase64("")
        setNewPhotoDescriptionTranslations({it: "", en: "", de: "", fr: ""})
        setUpdatePhotoGalleryId(undefined)
        return setNewPhotoModalShow(true)
    }

    const addNewLink = ()=>{
        if(!newLinkUrl || !newLinkLabel) return false
        const { links=[] } = state
        links.push({
            url: newLinkUrl ?? "",
            label: newLinkLabel ?? (newLinkUrl ?? "")
        })

        setNewLinkLabel("")
        setNewLinkUrl("")
        return dispatch({type: CHANGE_FORM_VALUE, payload:{formId: 'links', formValue:links}})
    }

    const removeLink = (index:number) =>{
        const {links=[]} = state
        if(links.length===0 || links.length<index+1) return
        
        links.splice(index,1)
        return dispatch({type: CHANGE_FORM_VALUE, payload:{formId: 'links', formValue:links}})
    }

    const deletePhotoFromGallery = async (galleryId: string) => {
        const result = await ReactSwal.fire({
            text: `Sei sicuro di voler eliminare questa foto dalla gallery?`,
            title: "Attenzione",
            icon: "warning",
            showConfirmButton:true,
            confirmButtonText: 'Elimina',
            cancelButtonText: 'Annulla',
            showCancelButton: true,
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try{
                    const response = await ProtectedAPI.delete(`places/${id}/deletePhoto/${galleryId}`)
                    if(response.data.status!=="ok") {
                        Swal.showValidationMessage(
                            `Si è verificato un errore, si prega di riprovare.`
                        )
                        return
                    }
                    return dispatch({type: CHANGE_FORM_VALUE, payload:{formId: 'gallery', formValue:response.data.gallery}})
                }catch(e) {
                    Swal.showValidationMessage(
                        `Si è verificato un errore, si prega di riprovare.`
                    )
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })

        if (result.isConfirmed) {
            return false
        }

        return true
    }

    const onChangeOpeningHours = (dayOfWeek:Days, part: Parts, side: Sides, hour: string) =>{
        return dispatch({type: SET_OPENING_HOURS, payload: {dayOfWeek, part, side, hour}})
    }

    const updatePlace = async ()=>{
        try {
            setPageStatus("SUBMITTING")
            const {name, description, period, street, zipCode, city, lat, lng, openingHoursText, openingInstructions, accessProblems, closingInstructions, closingProblems } = state
            if(!id) {
                return setPageStatus("INTERNAL_ERROR")
            }
            // check required
            if(!name || !description || !period || !street || !zipCode || !city || !lat || !lng || !openingHoursText || !openingInstructions || !accessProblems || !closingInstructions || !closingProblems) {
                setPageStatus("ERR_FIELDS_REQUIRED")
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                return
            }
    
            await ProtectedAPI.patch(`/places/${id}`, state)
            setPageStatus("IDLE")
            ReactSwal.fire("Luogo aggiornato correttamente!")
            getPlace(id)

        } catch(e) {
            setPageStatus("INTERNAL_ERROR")
        }
    }

    const onChangeField = (e:React.ChangeEvent<any>) =>{
        const {id, value} = e.target
        return dispatch({type: CHANGE_FORM_VALUE, payload: {formId: id, formValue: value}})
    }

    const onChangeTranslationField = (e:React.ChangeEvent<any>|null, value?: string, language?:string, formId?: string) =>{
        if(e) {
            const {value, dataset } = e.target
            const {id, lang} = dataset
            return dispatch({type: CHANGE_FORM_VALUE_TRANSLATION, payload: {formId: id, lang, formValue: value}})
        }
        return dispatch({type: CHANGE_FORM_VALUE_TRANSLATION, payload: {formId, lang:language!, formValue: value}})
    }

    const onCategoriesChange = (selectedOptions:any) => {
        setSelectedCategories(selectedOptions)
        const categories = selectedOptions.map((option:any)=>option.value)
        return dispatch({type: CHANGE_FORM_VALUE, payload: {formId: 'categories', formValue: categories}})
    }

    const onToggleDayPart = (e:React.ChangeEvent<any>) =>{
        const { dayofweek, part } = e.target.dataset
        if(!dayofweek || !part) return
        const hoursDefaultFrom = (part==="mor")?HOURS_DEFAULT_MOR_FROM:HOURS_DEFAULT_AFT_FROM
        const hoursDefaultTo = (part==="mor")?HOURS_DEFAULT_MOR_TO:HOURS_DEFAULT_AFT_TO
        dispatch({type: SET_OPENING_HOURS, payload: {dayOfWeek:dayofweek, part, side:"from", hour:(e.target.checked)?hoursDefaultFrom:""}})
        dispatch({type: SET_OPENING_HOURS, payload: {dayOfWeek:dayofweek, part, side:"to", hour:(e.target.checked)?hoursDefaultTo:""}})
    }
    
    const updatePhotoGallery = (galleryId:string) =>{
        const photoGalleryUpdating = state.gallery?.find(gallery=>gallery.id === galleryId)
        setNewPhotoDescription(photoGalleryUpdating?.description ?? "")
        setNewPhotoDescriptionTranslations(photoGalleryUpdating?.translations!)
        setUpdatePhotoGalleryId(galleryId)
        setNewPhotoModalShow(true)
    }

    const submitting = (pageStatus === "SUBMITTING")
    // generic function check for the field
    const isInErrorRequired = (fieldName:string) => (pageStatus === "ERR_FIELDS_REQUIRED" && !state[fieldName])
    const photoGalleryUpdating = state.gallery?.find(gallery=>gallery.id === updatePhotoGalleryId)

    return(
        <>
        <Header currentPage={"Places"} />
            <Container className="p-5">
                <div className="row">
                    <div className="col-sm-10 text-left">
                    <h1>Modifica luogo</h1>
                    </div>
                </div>
                {state.placeStatus==="INACTIVE" &&
                <div className="shadow p-3 mb-5 rounded bg-warning">
                    <p className="h5"><strong>Attenzione!</strong> Il luogo è attualmente in stato "Disattivo".</p>
                </div>
                }
                {state.placeStatus==="ACTIVE" &&
                <div className="shadow p-3 mb-5 rounded bg-success">
                    <p className="h5">Il luogo è attualmente in stato "Attivo".</p>
                </div>
                }
                <form className="mt-5">
                    <div className="shadow p-3 mb-5 bg-light rounded">
                        <h3 className="mb-4">Informazioni generali</h3>
                        <div className="row">
                            <div className="col-md-6">
                                
                                <div className="form-group">
                                    <label htmlFor="name">Nome</label>
                                    <Tabs>
                                        <Tab eventKey="tab_name_default" title="Default *">
                                            <input type="text" className="form-control" id="name" value={state.name} disabled={submitting} style={isInErrorRequired('name')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('name') && <span className="text-danger">Campo obbligatorio</span>}
                                        </Tab>
                                        <Tab eventKey="tab_name_it" title="IT">
                                            <input type="text" className="form-control" id="name_it" data-lang="it" data-id="name_translations" value={state.name_translations?.it} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_name_en" title="EN">
                                            <input type="text" className="form-control" id="name_en" data-lang="en" data-id="name_translations" value={state.name_translations?.en} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_name_de" title="DE">
                                            <input type="text" className="form-control" id="name_de" data-lang="de" data-id="name_translations" value={state.name_translations?.de} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_name_fr" title="FR">
                                            <input type="text" className="form-control" id="name_fr" data-lang="fr" data-id="name_translations" value={state.name_translations?.fr} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        
                                    </Tabs>

                                    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subtext">Periodo</label>
                                    <Tabs>
                                        <Tab eventKey="tab_period_default" title="Default *">
                                            <input type="text" className="form-control" id="period" value={state.period} disabled={submitting} style={isInErrorRequired('period')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('period') && <span className="text-danger">Campo obbligatorio</span>}
                                        </Tab>
                                        <Tab eventKey="tab_period_it" title="IT">
                                            <input type="text" className="form-control" id="period_it" data-lang="it" data-id="period_translations" value={state.period_translations?.it} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_period_en" title="EN">
                                            <input type="text" className="form-control" id="period_en" data-lang="en" data-id="period_translations" value={state.period_translations?.en} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_period_de" title="DE">
                                            <input type="text" className="form-control" id="period_de" data-lang="de" data-id="period_translations" value={state.period_translations?.de} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_period_fr" title="FR">
                                            <input type="text" className="form-control" id="period_fr" data-lang="fr" data-id="period_translations" value={state.period_translations?.fr} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        
                                    </Tabs>
                                    
                                </div>
                                
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                        <label htmlFor="categories">Categorie</label>
                                        <Select placeholder="Seleziona..." options={availableCategories} isMulti={true} value={selectedCategories} onChange={onCategoriesChange}></Select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="donateLink">Link per donazioni</label>
                                    <input type="text" className="form-control" id="donateLink" value={state.donateLink} disabled={submitting} onChange={onChangeField} />
                                </div>
                                <h5>Recapiti</h5>
                                <div className="form-group">
                                    <label htmlFor="infoDescription">Descrizione</label>
                                    <Tabs>
                                        <Tab eventKey="tab_infoDescription_default" title="Default *">
                                            <input type="text" className="form-control" id="infoDescription" value={state.infoDescription} disabled={submitting} onChange={onChangeField} />
                                        </Tab>
                                        <Tab eventKey="tab_infoDescription_it" title="IT">
                                            <input type="text" className="form-control" id="infoDescription_it" data-lang="it" data-id="infoDescription_translations" value={state.infoDescription_translations?.it} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_infoDescription_en" title="EN">
                                            <input type="text" className="form-control" id="infoDescription_en" data-lang="en" data-id="infoDescription_translations" value={state.infoDescription_translations?.en} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_infoDescription_de" title="DE">
                                            <input type="text" className="form-control" id="infoDescription_de" data-lang="de" data-id="infoDescription_translations" value={state.infoDescription_translations?.de} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        <Tab eventKey="tab_infoDescription_fr" title="FR">
                                            <input type="text" className="form-control" id="infoDescription_fr" data-lang="fr" data-id="infoDescription_translations" value={state.infoDescription_translations?.fr} disabled={submitting} onChange={onChangeTranslationField} />
                                        </Tab>
                                        
                                    </Tabs>
                                    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="infoPhone">Telefono</label>
                                    <input type="text" className="form-control" id="infoPhone" value={state.infoPhone} disabled={submitting} onChange={onChangeField} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="infoEmail">Email</label>
                                    <input type="text" className="form-control" id="infoEmail" value={state.infoEmail} disabled={submitting} onChange={onChangeField} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="description">Descrizione</label>
                                    <Tabs>
                                        <Tab eventKey="tab_description_default" title="Default (plain text) *">
                                            <textarea className="form-control" id="description" rows={10} value={state.description} disabled={submitting} style={isInErrorRequired('description')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('description') && <span className="text-danger">Campo obbligatorio</span>}
                                        </Tab>
                                        <Tab eventKey="tab_description_it" title="IT">
                                            <RichContent value={state.description_translations?.it} onChange={(v)=>{onChangeTranslationField(null, v, "it", "description_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_description_en" title="EN">
                                            <RichContent value={state.description_translations?.en} onChange={(v)=>{onChangeTranslationField(null, v, "en", "description_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_description_de" title="DE">
                                            <RichContent value={state.description_translations?.de} onChange={(v)=>{onChangeTranslationField(null, v, "de", "description_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_description_fr" title="FR">
                                            <RichContent value={state.description_translations?.fr} onChange={(v)=>{onChangeTranslationField(null, v, "fr", "description_translations")}} />
                                        </Tab>
                                        
                                    </Tabs>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow p-3 mb-5 bg-light rounded">
                        <h3 className="mb-4">Ulteriori approfondimenti</h3>
                            {state?.links?.map((link:{id?:string, label: string, url: string}, index: number) => {
                                const {url, label} = link
                                return (
                                    <div className="row border-bottom" key={index.toString()}>
                                        <div className="col-md-5 pl-3">
                                            <a href={`//${url}`} target="_new">{url}</a>
                                        </div>
                                        <div className="col-md-5 pl-3">
                                            {label}
                                        </div>
                                        <div className="col-md-2 align-center">
                                            <div data-tip="elimina link" className="p-1 text-danger" style={{cursor: 'pointer'}} onClick={()=>{removeLink(index)}}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {state?.links?.length===0 && 
                            <p>nessun link presente.</p>
                            }
                            <div className="row mt-3">
                                <div className="col-md-5 form-group">
                                    <label htmlFor="name">Link</label>
                                    <input type="text" className="form-control" value={newLinkUrl} onChange={(e)=>{setNewLinkUrl(e.target.value)}} />
                                </div>
                                <div className="col-md-5 form-group">
                                    <label htmlFor="name">Testo descrittivo</label>
                                    <input type="text" className="form-control" value={newLinkLabel} onChange={(e)=>{setNewLinkLabel(e.target.value)}} />
                                </div>
                                <div className="col-md-2 form-group">
                                    <label htmlFor="name">&nbsp;</label>
                                    <button type="button" className="form-control btn btn-success" onClick={()=>{addNewLink()}}>Aggiungi</button>
                                </div>
                            </div>
                    </div>
                    <div className="shadow p-3 mb-5 bg-light rounded">
                        <h3 className="mb-4">Foto</h3>
                        <div className="row">
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label htmlFor="name">Foto rappresentativa</label>
                                    <div className="custom-file">
                                        <input type="file" accept="image/*" className="custom-file-input" id="photo" name="photo" disabled={submitting} onChange={onChangeMainPhoto}/>
                                        <label className="custom-file-label" htmlFor="photo">Scegli...</label>
                                    </div>
                                </div>
                                <div className="bordered text-center">
                                    <img src={state.photo || '/images/photo-placeholder.jpg'} className="img-fluid shadow" alt="" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="d-flex flex-row align-items-center justify-content-start">
                                    <label className="p-2 pt-3">Gallery </label>
                                    <div className="text-info" data-tip="Aggiungi foto" style={{cursor: 'pointer'}} onClick={()=>{addPhotoToGallery()}}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </div>
                                </div>
                                    {(!state.gallery || state.gallery.length===0) &&
                                        <p className="mt-5">Nessuna foto nella gallery.</p>
                                    }
                                    {state.gallery?.map((photoElement:{description:string, translations: Translations, photo:string, id: string})=>{
                                        return (
                                        <div key={photoElement.id} className="row">
                                            <div className="col-md-2 p-2">
                                                <div className="bordered text-center">
                                                    <div data-tip="elimina foto" className="p-1 text-danger" style={{cursor: 'pointer', position: 'absolute', right:5,top:5}} onClick={()=>{deletePhotoFromGallery(photoElement.id)}}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </div>
                                                    <div data-tip="modifica foto" className="p-1 text-info" style={{cursor: 'pointer', position: 'absolute', right:25,top:5}} onClick={()=>{updatePhotoGallery(photoElement.id)}}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </div>
                                                    <img alt="" style={{cursor: 'pointer'}} src={photoElement.photo || '/images/photo-placeholder.jpg'} className="img-fluid shadow" onClick={()=>{photoElement.photo && window.open(photoElement.photo)}} />
                                                </div>
                                            </div>
                                            <div className="col-md-10 p-2 text-left">
                                            <Tabs>
                                                <Tab eventKey={`${photoElement.id}_gallery_default`} title="Default">
                                                    <p>{photoElement.description}</p>
                                                </Tab>
                                                <Tab eventKey={`${photoElement.id}_gallery_it`} title="IT">
                                                    {parser(photoElement.translations?.it ?? "")}
                                                </Tab>
                                                <Tab eventKey={`${photoElement.id}_gallery_en`} title="EN">
                                                {parser(photoElement.translations?.en ?? "")}
                                                </Tab>
                                                <Tab eventKey={`${photoElement.id}_gallery_de`} title="DE">
                                                {parser(photoElement.translations?.de ?? "")}
                                                </Tab>
                                                <Tab eventKey={`${photoElement.id}_gallery_fr`} title="FR">
                                                {parser(photoElement.translations?.fr ?? "")}
                                                </Tab>
                                                
                                            </Tabs>
                                            </div>
                                        </div>
                                        )
                                    })
                                    }
                            </div>
                        </div>
                    </div>
                    <div className="shadow p-3 mb-5 bg-light rounded">
                        <h3 className="mb-4">Indirizzo e localizzazione</h3>
                        <div className="row">
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label htmlFor="city">Città *</label>
                                    <input type="text" className="form-control" id="city" value={state.city} disabled={submitting} style={isInErrorRequired('city')?style.error:undefined} onChange={onChangeField} required />
                                    {isInErrorRequired('city') && <span className="text-danger">Campo obbligatorio</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label htmlFor="cap">Zip Code *</label>
                                    <input type="text" className="form-control" id="zipCode" value={state.zipCode} disabled={submitting} style={isInErrorRequired('zipCode')?style.error:undefined} onChange={onChangeField} required />
                                    {isInErrorRequired('zipCode') && <span className="text-danger">Campo obbligatorio</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="street">Via *</label>
                                    <input type="text" className="form-control" id="street" value={state.street} disabled={submitting} style={isInErrorRequired('street')?style.error:undefined} onChange={onChangeField} required />
                                    {isInErrorRequired('street') && <span className="text-danger">Campo obbligatorio</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="street">Geolocalizzazione</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="small" htmlFor="lat">Lat. *</label>
                                            <input type="number" className="form-control" id="lat" value={state.lat} disabled={submitting} style={isInErrorRequired('lat')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('lat') && <span className="text-danger">Campo obbligatorio</span>}
                                        </div>
                                        <div className="col-md-6">
                                            <label className="small" htmlFor="lng">Lng. *</label>
                                            <input type="number" className="form-control" id="lng" value={state.lng} disabled={submitting} style={isInErrorRequired('lng')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('lng') && <span className="text-danger">Campo obbligatorio</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow p-3 mb-5 bg-light rounded">
                        <h3 className="mb-4">Orari di apertura</h3>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label className="small" htmlFor="openingHoursText">Descrizione testuale</label>
                                <Tabs>
                                    <Tab eventKey="tab_openingHoursText_default" title="Default *">
                                        <input type="text" className="form-control" id="openingHoursText" value={state.openingHoursText} disabled={submitting} style={isInErrorRequired('openingHoursText')?style.error:undefined} onChange={onChangeField} required />
                                        {isInErrorRequired('openingHoursText') && <span className="text-danger">Campo obbligatorio</span>}
                                    </Tab>
                                    <Tab eventKey="tab_openingHoursText_it" title="IT">
                                        <input type="text" className="form-control" id="openingHoursText_it" data-lang="it" data-id="openingHoursText_translations" value={state.openingHoursText_translations?.it} disabled={submitting} onChange={onChangeTranslationField} />
                                    </Tab>
                                    <Tab eventKey="tab_openingHoursText_en" title="EN">
                                        <input type="text" className="form-control" id="openingHoursText_en" data-lang="en" data-id="openingHoursText_translations" value={state.openingHoursText_translations?.en} disabled={submitting} onChange={onChangeTranslationField} />
                                    </Tab>
                                    <Tab eventKey="tab_openingHoursText_de" title="DE">
                                        <input type="text" className="form-control" id="openingHoursText_de" data-lang="de" data-id="openingHoursText_translations" value={state.openingHoursText_translations?.de} disabled={submitting} onChange={onChangeTranslationField} />
                                    </Tab>
                                    <Tab eventKey="tab_openingHoursText_fr" title="FR">
                                        <input type="text" className="form-control" id="openingHoursText_fr" data-lang="fr" data-id="openingHoursText_translations" value={state.openingHoursText_translations?.fr} disabled={submitting} onChange={onChangeTranslationField} />
                                    </Tab>
                                    
                                </Tabs>
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="mon" data-part="mor" checked={!!state.openingHours.mon.mor.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_mon_mor" />
                                    <label htmlFor="toggle_mon_mor" >Lunedì mattina</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_mon_mor_from" disabled={submitting || !state.openingHours.mon.mor.from} value={state.openingHours.mon.mor.from} onChange={(e)=>{onChangeOpeningHours('mon','mor','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_mon_mor_to" disabled={submitting || !state.openingHours.mon.mor.to} value={state.openingHours.mon.mor.to} onChange={(e)=>{onChangeOpeningHours('mon','mor','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="mon" data-part="aft" checked={!!state.openingHours.mon.aft.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_mon_aft" />
                                    <label htmlFor="toggle_mon_aft" >Lunedì pomeriggio</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_mon_aft_from" disabled={submitting || !state.openingHours.mon.aft.from} value={state.openingHours.mon.aft.from} onChange={(e)=>{onChangeOpeningHours('mon','aft','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_mon_aft_to" disabled={submitting || !state.openingHours.mon.aft.to} value={state.openingHours.mon.aft.to} onChange={(e)=>{onChangeOpeningHours('mon','aft','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="tus" data-part="mor" checked={!!state.openingHours.tus.mor.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_tus_mor" />
                                    <label htmlFor="toggle_tus_mor" >Martedì mattina</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_tus_mor_from" disabled={submitting || !state.openingHours.tus.mor.from} value={state.openingHours.tus.mor.from} onChange={(e)=>{onChangeOpeningHours('tus','mor','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_tus_mor_to" disabled={submitting || !state.openingHours.tus.mor.to} value={state.openingHours.tus.mor.to} onChange={(e)=>{onChangeOpeningHours('tus','mor','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="tus" data-part="aft" checked={!!state.openingHours.tus.aft.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_tus_aft" />
                                    <label htmlFor="toggle_tus_aft" >Martedì pomeriggio</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_tus_aft_from" disabled={submitting || !state.openingHours.tus.aft.from} value={state.openingHours.tus.aft.from} onChange={(e)=>{onChangeOpeningHours('tus','aft','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_tus_aft_to" disabled={submitting || !state.openingHours.tus.aft.to} value={state.openingHours.tus.aft.to} onChange={(e)=>{onChangeOpeningHours('tus','aft','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="wed" data-part="mor" checked={!!state.openingHours.wed.mor.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_wed_mor" />
                                    <label htmlFor="toggle_wed_mor" >Mercoledì mattina</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_wed_mor_from" disabled={submitting || !state.openingHours.wed.mor.from} value={state.openingHours.wed.mor.from} onChange={(e)=>{onChangeOpeningHours('wed','mor','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_wed_mor_to" disabled={submitting || !state.openingHours.wed.mor.to} value={state.openingHours.wed.mor.to} onChange={(e)=>{onChangeOpeningHours('wed','mor','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="wed" data-part="aft" checked={!!state.openingHours.wed.aft.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_wed_aft" />
                                    <label htmlFor="toggle_wed_aft" >Mercoledì pomeriggio</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_wed_aft_from" disabled={submitting || !state.openingHours.wed.aft.from} value={state.openingHours.wed.aft.from} onChange={(e)=>{onChangeOpeningHours('wed','aft','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_wed_aft_to" disabled={submitting || !state.openingHours.wed.aft.to} value={state.openingHours.wed.aft.to} onChange={(e)=>{onChangeOpeningHours('wed','aft','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="thu" data-part="mor" checked={!!state.openingHours.thu.mor.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_thu_mor" />
                                    <label htmlFor="toggle_thu_mor" >Giovedì mattina</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_thu_mor_from" disabled={submitting || !state.openingHours.thu.mor.from} value={state.openingHours.thu.mor.from} onChange={(e)=>{onChangeOpeningHours('thu','mor','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_thu_mor_to" disabled={submitting || !state.openingHours.thu.mor.to} value={state.openingHours.thu.mor.to} onChange={(e)=>{onChangeOpeningHours('thu','mor','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="thu" data-part="aft" checked={!!state.openingHours.thu.aft.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_thu_aft" />
                                    <label htmlFor="toggle_thu_aft" >Giovedì pomeriggio</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_thu_aft_from" disabled={submitting || !state.openingHours.thu.aft.from} value={state.openingHours.thu.aft.from} onChange={(e)=>{onChangeOpeningHours('thu','aft','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_thu_aft_to" disabled={submitting || !state.openingHours.thu.aft.to} value={state.openingHours.thu.aft.to} onChange={(e)=>{onChangeOpeningHours('thu','aft','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="fri" data-part="mor" checked={!!state.openingHours.fri.mor.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_fri_mor" />
                                    <label htmlFor="toggle_fri_mor" >Venerdì mattina</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_fri_mor_from" disabled={submitting || !state.openingHours.fri.mor.from} value={state.openingHours.fri.mor.from} onChange={(e)=>{onChangeOpeningHours('fri','mor','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_fri_mor_to" disabled={submitting || !state.openingHours.fri.mor.to} value={state.openingHours.fri.mor.to} onChange={(e)=>{onChangeOpeningHours('fri','mor','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="fri" data-part="aft" checked={!!state.openingHours.fri.aft.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_fri_aft" />
                                    <label htmlFor="toggle_fri_aft" >Venerdì pomeriggio</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_fri_aft_from" disabled={submitting || !state.openingHours.fri.aft.from} value={state.openingHours.fri.aft.from} onChange={(e)=>{onChangeOpeningHours('fri','aft','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_fri_aft_to" disabled={submitting || !state.openingHours.fri.aft.to} value={state.openingHours.fri.aft.to} onChange={(e)=>{onChangeOpeningHours('fri','aft','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="sat" data-part="mor" checked={!!state.openingHours.sat.mor.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_sat_mor" />
                                    <label htmlFor="toggle_sat_mor" >Sabato mattina</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_sat_mor_from" disabled={submitting || !state.openingHours.sat.mor.from} value={state.openingHours.sat.mor.from} onChange={(e)=>{onChangeOpeningHours('sat','mor','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_sat_mor_to" disabled={submitting || !state.openingHours.sat.mor.to} value={state.openingHours.sat.mor.to} onChange={(e)=>{onChangeOpeningHours('sat','mor','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="sat" data-part="aft" checked={!!state.openingHours.sat.aft.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_sat_aft" />
                                    <label htmlFor="toggle_sat_aft" >Sabato pomeriggio</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_sat_aft_from" disabled={submitting || !state.openingHours.sat.aft.from} value={state.openingHours.sat.aft.from} onChange={(e)=>{onChangeOpeningHours('sat','aft','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_sat_aft_to" disabled={submitting || !state.openingHours.sat.aft.to} value={state.openingHours.sat.aft.to} onChange={(e)=>{onChangeOpeningHours('sat','aft','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="sun" data-part="mor" checked={!!state.openingHours.sun.mor.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_sun_mor" />
                                    <label htmlFor="toggle_sun_mor" >Domenica mattina</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_sun_mor_from" disabled={submitting || !state.openingHours.sun.mor.from} value={state.openingHours.sun.mor.from} onChange={(e)=>{onChangeOpeningHours('sun','mor','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_sun_mor_to" disabled={submitting || !state.openingHours.sun.mor.to} value={state.openingHours.sun.mor.to} onChange={(e)=>{onChangeOpeningHours('sun','mor','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input type="checkbox" data-dayofweek="sun" data-part="aft" checked={!!state.openingHours.sun.aft.from} onChange={onToggleDayPart} className="mr-2" disabled={submitting} id="toggle_sun_aft" />
                                    <label htmlFor="toggle_sun_aft" >Domenica pomeriggio</label>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_sun_aft_from" disabled={submitting || !state.openingHours.sun.aft.from} value={state.openingHours.sun.aft.from} onChange={(e)=>{onChangeOpeningHours('sun','aft','from', e.target.value)}} />
                                        </div>
                                        <div className="col-md-1 text-center"><span>-</span></div>
                                        <div className="col-md-5">
                                            <input type="time" className="form-control" id="openingHours_sun_aft_to" disabled={submitting || !state.openingHours.sun.aft.to} value={state.openingHours.sun.aft.to} onChange={(e)=>{onChangeOpeningHours('sun','aft','to', e.target.value)}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="shadow p-3 mb-5 bg-light rounded">
                        <h3 className="mb-4">Identificativi Salto / QR Code</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="name">IQ</label>
                                    <input type="text" className="form-control" id="saltoIqId" value={state.saltoIqId} disabled={submitting} onChange={onChangeField} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Lock</label>
                                    <input type="text" className="form-control" id="saltoLockId" value={state.saltoLockId} disabled={submitting} onChange={onChangeField} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="QRCodeLink">Link QR Code</label>
                                    <input type="text" className="form-control" id="QRCodeLink" value={state.QRCodeLink} disabled={submitting} onChange={onChangeField} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow p-3 mb-5 bg-light rounded">
                        <h3 className="mb-4">Istruzioni di apertura / Problemi di accesso</h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="openingInstructions">Istruzioni di apertura</label>
                                    <Tabs>
                                        <Tab eventKey="tab_openingInstructions_default" title="Default (plain text) *">
                                            <textarea className="form-control" id="openingInstructions" rows={10} value={state.openingInstructions} disabled={submitting} style={isInErrorRequired('openingInstructions')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('openingInstructions') && <span className="text-danger">Campo obbligatorio</span>}
                                        </Tab>
                                        <Tab eventKey="tab_openingInstructions_it" title="IT">
                                            <RichContent value={state.openingInstructions_translations?.it} onChange={(v)=>{onChangeTranslationField(null, v, "it", "openingInstructions_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_openingInstructions_en" title="EN">
                                            <RichContent value={state.openingInstructions_translations?.en} onChange={(v)=>{onChangeTranslationField(null, v, "en", "openingInstructions_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_openingInstructions_de" title="DE">
                                            <RichContent value={state.openingInstructions_translations?.de} onChange={(v)=>{onChangeTranslationField(null, v, "de", "openingInstructions_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_openingInstructions_fr" title="FR">
                                            <RichContent value={state.openingInstructions_translations?.fr} onChange={(v)=>{onChangeTranslationField(null, v, "fr", "openingInstructions_translations")}} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="accessProblems">Istruzioni problemi di accesso</label>
                                    <Tabs>
                                        <Tab eventKey="tab_accessProblems_default" title="Default (plain text) *">
                                            <textarea className="form-control" id="accessProblems" rows={10} value={state.accessProblems} disabled={submitting} style={isInErrorRequired('accessProblems')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('accessProblems') && <span className="text-danger">Campo obbligatorio</span>}
                                        </Tab>
                                        <Tab eventKey="tab_accessProblems_it" title="IT">
                                            <RichContent value={state.accessProblems_translations?.it} onChange={(v)=>{onChangeTranslationField(null, v, "it", "accessProblems_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_accessProblems_en" title="EN">
                                            <RichContent value={state.accessProblems_translations?.en} onChange={(v)=>{onChangeTranslationField(null, v, "en", "accessProblems_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_accessProblems_de" title="DE">
                                            <RichContent value={state.accessProblems_translations?.de} onChange={(v)=>{onChangeTranslationField(null, v, "de", "accessProblems_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_accessProblems_fr" title="FR">
                                            <RichContent value={state.accessProblems_translations?.fr} onChange={(v)=>{onChangeTranslationField(null, v, "fr", "accessProblems_translations")}} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow p-3 mb-5 bg-light rounded">
                        <h3 className="mb-4">Istruzioni di chiusura / Problemi di chiusura</h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="closingInstructions">Istruzioni di chiusura</label>
                                    <Tabs>
                                        <Tab eventKey="tab_closingInstructions_default" title="Default (plain text) *">
                                            <textarea className="form-control" id="closingInstructions" rows={10} value={state.closingInstructions} disabled={submitting} style={isInErrorRequired('closingInstructions')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('closingInstructions') && <span className="text-danger">Campo obbligatorio</span>}
                                        </Tab>
                                        <Tab eventKey="tab_closingInstructions_it" title="IT">
                                            <RichContent value={state.closingInstructions_translations?.it} onChange={(v)=>{onChangeTranslationField(null, v, "it", "closingInstructions_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_closingInstructions_en" title="EN">
                                            <RichContent value={state.closingInstructions_translations?.en} onChange={(v)=>{onChangeTranslationField(null, v, "en", "closingInstructions_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_closingInstructions_de" title="DE">
                                            <RichContent value={state.closingInstructions_translations?.de} onChange={(v)=>{onChangeTranslationField(null, v, "de", "closingInstructions_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_closingInstructions_fr" title="FR">
                                            <RichContent value={state.closingInstructions_translations?.fr} onChange={(v)=>{onChangeTranslationField(null, v, "fr", "closingInstructions_translations")}} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="closingProblems">Istruzioni problemi di chiusura</label>
                                    <Tabs>
                                        <Tab eventKey="tab_closingProblems_default" title="Default (plain text) *">
                                            <textarea className="form-control" id="closingProblems" rows={10} value={state.closingProblems} disabled={submitting} style={isInErrorRequired('closingProblems')?style.error:undefined} onChange={onChangeField} required />
                                            {isInErrorRequired('closingProblems') && <span className="text-danger">Campo obbligatorio</span>}
                                        </Tab>
                                        <Tab eventKey="tab_closingProblems_it" title="IT">
                                            <RichContent value={state.closingProblems_translations?.it} onChange={(v)=>{onChangeTranslationField(null, v, "it", "closingProblems_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_closingProblems_en" title="EN">
                                            <RichContent value={state.closingProblems_translations?.en} onChange={(v)=>{onChangeTranslationField(null, v, "en", "closingProblems_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_closingProblems_de" title="DE">
                                            <RichContent value={state.closingProblems_translations?.de} onChange={(v)=>{onChangeTranslationField(null, v, "de", "closingProblems_translations")}} />
                                        </Tab>
                                        <Tab eventKey="tab_closingProblems_fr" title="FR">
                                            <RichContent value={state.closingProblems_translations?.fr} onChange={(v)=>{onChangeTranslationField(null, v, "fr", "closingProblems_translations")}} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shadow p-3 mb-5 bg-light rounded text-right">
                        {!submitting &&
                        <>
                        <button type="button" className="btn btn-primary btn-lg m-1" onClick={updatePlace}>Salva</button>
                        <Link to="/places" className="btn btn-secondary btn-lg m-1">Annulla</Link>
                        </>
                        }
                        {submitting &&
                        <>
                        
                        <span className="text-primary align-middle">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" /> 
                            <span className="ml-2">Creazione in corso...</span>
                            </span>
                        </>
                        }
                    </div>
                
                </form>
                <ReactTooltip />
                <Modal size='lg' show={!!newPhotoModalShow} onHide={()=>{return setNewPhotoModalShow(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${updatePhotoGalleryId?`Modifica Foto`:`Nuovo Foto`}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="name">Foto rappresentativa</label>
                            <div className="custom-file">
                                <input type="file" accept="image/*" className="custom-file-input" id="newPhotoBase64" name="newPhotoBase64" disabled={submitting} onChange={onChangeNewPhoto}/>
                                <label className="custom-file-label" htmlFor="newPhotoBase64">Scegli...</label>
                            </div>
                        </div>
                        <div className="bordered text-center">
                            <img src={newPhotoBase64 || (photoGalleryUpdating?.photo ?? '/images/photo-placeholder.jpg')} className="img-fluid shadow" alt="" />
                        </div>
                        <label className="mt-3" htmlFor="newPhotoDescription">Aggiungi una descrizione alla foto:</label>
                        <Tabs>
                            <Tab eventKey="tab_newphotodescription_default" title="Default (plain text)">
                            <textarea  className="form-control" id="newPhotoDescription" value={newPhotoDescription?.length>0?newPhotoDescription:photoGalleryUpdating?.description} disabled={newPhotoStatus==="UPLOADING"}  onChange={(e)=>{setNewPhotoDescription(e.target.value)}} />
                            </Tab>
                            <Tab eventKey="tab_newphotodescription_it" title="IT">
                                <RichContent height={200} value={(newPhotoDescriptionTranslations?.it ?? '').length>0?newPhotoDescriptionTranslations?.it:photoGalleryUpdating?.translations?.it} onChange={(v)=>{setNewPhotoDescriptionTranslations({...newPhotoDescriptionTranslations, it: v})}} />
                            </Tab>
                            <Tab eventKey="tab_newphotodescription_en" title="EN">
                                <RichContent height={200} value={(newPhotoDescriptionTranslations?.en ?? '').length>0?newPhotoDescriptionTranslations?.en:photoGalleryUpdating?.translations?.en} onChange={(v)=>{setNewPhotoDescriptionTranslations({...newPhotoDescriptionTranslations, en: v})}} />
                            </Tab>
                            <Tab eventKey="tab_newphotodescription_de" title="DE">
                                <RichContent height={200} value={(newPhotoDescriptionTranslations?.de ?? '').length>0?newPhotoDescriptionTranslations?.de:photoGalleryUpdating?.translations?.de} onChange={(v)=>{setNewPhotoDescriptionTranslations({...newPhotoDescriptionTranslations, de: v})}} />
                            </Tab>
                            <Tab eventKey="tab_newphotodescription_fr" title="FR">
                                <RichContent height={200} value={(newPhotoDescriptionTranslations?.fr ?? '').length>0?newPhotoDescriptionTranslations?.fr:photoGalleryUpdating?.translations?.fr} onChange={(v)=>{setNewPhotoDescriptionTranslations({...newPhotoDescriptionTranslations, fr: v})}} />
                            </Tab>
                            
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                    {newPhotoStatus!=="UPLOADING" &&
                    <>
                    {newPhotoStatus==="ERROR" && 
                        <span className="text-danger mr-2">Si è verificato un errore.</span>
                    }
                    <button  className="btn btn-secondary mt-3" onClick={()=>{setNewPhotoModalShow(false)}}>
                        Annulla
                    </button>
                    <button  className="btn btn-success mt-3" onClick={()=>{uploadGalleryPhoto()}}>
                    {`${updatePhotoGalleryId?`Salva`:`Crea`}`}
                    </button>
                    </>
                    }
                    {newPhotoStatus==="CREATING" &&
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                    }
                    </Modal.Footer>
            </Modal>
            </Container>
        </>
    )
}

const style = {
    error: {
        borderWidth: 1,
        borderColor: '#f00'
    }
}
export default Place