import { useState } from 'react'
import Header from 'src/Components/Common/Header'
import { ProtectedAPI } from 'src/Api/Common'
import Container from 'react-bootstrap/esm/Container'
import { IUser} from 'src/Interfaces/User'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {format} from 'date-fns'
import {countries} from 'src/Utils/Countries'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ReactSwal = withReactContent(Swal)


const Users = ()=> {
    const [user, setUser] = useState<IUser|undefined>(undefined)
    const [searchState, setSearchState] = useState("IDLE")
    const [username, setUsername] = useState("")


    const searchUser = async () => {
        if(!!!username) return
        setSearchState("SEARCHING")
        try{
            const response = await ProtectedAPI.get(`users/${username}`)
            setUser(response.data.user)
            setSearchState("IDLE")
        } catch(e:any) {
            const returnStatus = e.response?.request?.status
            if(returnStatus===404) return setSearchState("NOTFOUND")
            return setSearchState("ERROR")
        }
    }

    const changeUserStatus = async(locked:boolean) =>{
        if(!user) return false

        const text = (!!locked)?"Sei sicuro di voler disabilitare questo utente?":"Sei sicuro di voler abilitare questo utente?"
        const result = await ReactSwal.fire({
            text,
            title: "Attenzione",
            icon: "warning",
            showConfirmButton:true,
            confirmButtonText: 'Procedi',
            cancelButtonText: 'Annulla',
            showCancelButton: true,
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try{
                    const response = await ProtectedAPI.patch(`users/${user.id}/setLocked/${locked}`)
                    if(response.data.status!=="ok") {
                        Swal.showValidationMessage(
                            `Si è verificato un errore, si prega di riprovare.`
                        )
                        return
                    }
                    const userResponse = await ProtectedAPI.get(`users/${username}`)
                    setUser(userResponse.data.user)
                }catch(e) {
                    Swal.showValidationMessage(
                        `Si è verificato un errore, si prega di riprovare.`
                    )
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })

        if (result.isConfirmed) {
            return false
        }

        return true
    }

    const registeredDate = (user?.registeredAt)?format(new Date(user.registeredAt), "dd.MM.yyyy"):""

    const country = (user?.address?.country)?countries.find(c=>c.code===user.address.country)?.country ?? " - ":"";

    return (
        <>
            <Header currentPage={"Users"} />
            <Container className="p-5">
                <div className="row">
                    <div className="col-sm-10 text-left">
                        <h1>Ricerca utente</h1>
                    </div>
                </div>
                <div className="row">
                    <form style={{width: '100%'}}>
                        <div className="form-group col-md-6">
                            <label htmlFor="name">Username / Email:</label>
                            <input type="text" className="form-control" id="username" value={username} disabled={searchState==='SEARCHING'}  style={searchState==='ERROR'?style.error:undefined} onChange={(e)=>{setUsername(e.target.value)}} />
                            {searchState==='ERROR' && <span className="text-danger">Si è verificato un errore</span>}
                        </div>
                        <div className="col-md-6">
                            <button type="button" className="btn btn-success" onClick={()=>{return searchUser()}}>Cerca</button>
                            {searchState==="SEARCHING" &&
                                <span className="ml-3"><FontAwesomeIcon icon={faSpinner} spin size="2x" /></span>
                            }
                        </div>
                    </form>
                </div>
                <div className="mt-5 row">
                    
                    {searchState==="NOTFOUND" &&
                        <h3>Nessun utente trovato.</h3>
                    }
                    {searchState==="IDLE" && user &&
                    <>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="username">Username / Email</label>
                            <input type="text" id="username" disabled readOnly className="form-control" value={user.username} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="firstname">Nome</label>
                            <input type="text" id="firstname" disabled readOnly className="form-control" value={user.firstname} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastname">Cognome</label>
                            <input type="text" id="lastname" disabled readOnly className="form-control" value={user.lastname} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastname">Data registrazione</label>
                            <input type="text" id="lastname" disabled readOnly className="form-control" value={registeredDate} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Telefono</label>
                            <input type="text" id="phone" disabled readOnly className="form-control" value={user.phone ?? ""} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="street">Indirizzo</label>
                            <input type="text" id="street" disabled readOnly className="form-control" value={user.address?.street ?? ""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="zipCode">CAP</label>
                            <input type="text" id="zipCode" disabled readOnly className="form-control" value={user.address?.zipCode ?? ""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="city">Città</label>
                            <input type="text" id="city" disabled readOnly className="form-control" value={user.address?.city ?? ""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="country">Nazione</label>
                            <input type="text" id="country" disabled readOnly className="form-control" value={country} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="locked">Abilitato</label>
                            <div>
                                <span className={user.locked?"text-danger":'text-success'}>{`${user.locked?`No`:`Sì`}`}</span>
                                <button className={`ml-2 btn btn-sm ${user.locked?"btn-success":'btn-danger'}`} onClick={()=>{changeUserStatus(!user.locked)}}>{`${user.locked?`Abilita`:`Disabilita`}`}</button>
                            </div>
                        </div>
                    </div>
                    </>
                    }
                </div>
            </Container>
        </>
    )
}

const style = {
    error: {
        borderWidth: 1,
        borderColor: '#f00'
    }
}

export default Users