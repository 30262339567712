import Container from 'react-bootstrap/esm/Container'
import Header from 'src/Components/Common/Header'
import { Link } from "react-router-dom"
import { ProtectedAPI } from 'src/Api/Common'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faEdit, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { IPlace } from 'src/Interfaces/Places'
import ReactTooltip from 'react-tooltip'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Modal } from 'react-bootstrap'

const ReactSwal = withReactContent(Swal)



const Places = ()=>{

    const [placesList, setPlacesList] = useState<Array<IPlace>>([])
    const [pageState, setPageState] = useState("IDLE")
    const [placeChangingStatus, setPlaceChangingStatus] = useState("")
    const [newPlaceModalShow, setNewPlaceModalShow] = useState(false)
    const [placeCreatingStatus, setPlaceCreatingStatus] = useState("")
    const [newPlaceName, setNewPlaceName] = useState("Nuovo luogo")

    const getPlaces = async ()=>{
        try{
            setPageState("LOADING_PLACES")
            const response = await ProtectedAPI.get('/places')
            if(response.data.status!=="ok") {
                setPlacesList([])
                return setPageState("LOADING_PLACES_ERROR")
            }

            setPlacesList(response.data.places)
            return setPageState("IDLE")

        } catch(err) {
            setPlacesList([])
            setPageState("LOADING_PLACES_ERROR")
        }
    }

    const setPlaceStatus = async (_id:string, status:string) =>{
        if(!_id || !status) return
        try{
            setPageState("CHANGING_PLACE_STATUS")
            setPlaceChangingStatus(_id)
            const response = await ProtectedAPI.patch(`/places/${_id}/status/${status}`)
            setPlaceChangingStatus("")
            setPageState("IDLE")
            if(response.data.status==="ok") {
                return getPlaces()
            }

            await ReactSwal.fire({
                text: "Si è verificato un errore nella pubblicazione del luogo.",
                icon: "error",
            })
            return

        } catch(err:any) {
            const errorCode = err.response?.status
            let errorText = "Si è verificato un errore nella pubblicazione del luogo."
            if(errorCode===400) {
                errorText = "Il luogo non è pubblicabile perchè mancano alcune informazioni fondamentali per la pubblicazione."
            }
            await ReactSwal.fire({
                text: errorText,
                icon: "error",
            })
            setPlaceChangingStatus("")
            return setPageState("IDLE")
        }
    }

    useEffect(()=>{
        getPlaces()
    }, [])

    const showCreatePlace = ()=>{
        setNewPlaceName("Nuovo luogo")
        setPlaceCreatingStatus("")
        return setNewPlaceModalShow(true)
    }

    const createPlace = async ()=>{
        setPlaceCreatingStatus("CREATING")
        try{
            const response = await ProtectedAPI.post('/places', {name: newPlaceName})
            window.location.href=`/places/edit/${response.data.id}`
        } catch(e) {
            setPlaceCreatingStatus("ERROR")
        }
    }

    return (
        <>
        <Header currentPage={"Places"} />
        <Container className="p-5">
            <div className="row">
                <div className="col-sm-10 text-left">
                    <h1>Elenco dei luoghi</h1>
                </div>
                <div className="col-sm-2 text-right align-middle">
                    <button onClick={()=>{return showCreatePlace()}} className="btn btn-primary">Nuovo</button>
                </div>
            </div>
            <div className="row">
                {pageState==="LOADING_PLACES" &&
                <div className="col-sm-12 text-center my-5">
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                    <p className="mt-3">Caricamento in corso...</p>
                </div>
                }
                {pageState==="LOADING_PLACES_ERROR" &&
                    <div className="col-md-12 text-center my-5">
                        <p className="mt-3">Errore nel caricamento.</p>
                        <button className="btn btn-primary mt-3" onClick={()=>{getPlaces()}}>Riprova</button>
                    </div>
                }
                {["IDLE","CHANGING_PLACE_STATUS"].includes(pageState) &&
                    <div className="p-2 m-2" style={{width:'100%'}}>
                        <div className="row mb-2">
                            <div className="col-md-3 text-left"><strong>Nome</strong></div>
                            <div className="col-md-4 text-left"><strong>Periodo</strong></div>
                            <div className="col-md-3 text-left"><strong>Stato</strong></div>
                            <div className="col-md-2 text-right"><strong>Azioni</strong></div>
                        </div>
                        <hr/>
                        {placesList.length===0 &&
                            <div className="col-md-12 text-center my-5">
                            <p className="mt-3">Nessun risultato.</p>
                        </div>
                        }
                        {placesList.length>0 && placesList.map((place:IPlace, index: number)=>{
                        return (
                            <div key={index}>
                            <div className="row" style={{width:'100%'}}>
                                <div className="col-md-3 text-left">{place.name}</div>
                                <div className="col-md-4 text-left">{place.period}</div>
                                <div className="col-md-3 text-left">
                                        {place.placeStatus==="INACTIVE" && <p className="text-warning">Non pubblicato</p>}
                                        {place.placeStatus==="ACTIVE" && <p className="text-success">Pubblicato</p>}
                                </div>
                                <div className="col-md-2 text-right">
                                    <Link to={`/places/edit/${place._id}`} className="btn btn-primary mr-2" data-tip="Modifica"><FontAwesomeIcon icon={faEdit} /></Link>
                                    {placeChangingStatus!==place._id && place.placeStatus==="INACTIVE" && <button className="btn btn-success" onClick={()=>{setPlaceStatus(place._id, "ACTIVE")}} data-tip="Pubblica!"><FontAwesomeIcon icon={faEye} /></button>}
                                    {placeChangingStatus!==place._id && place.placeStatus==="ACTIVE" && <button className="btn btn-warning" onClick={()=>{setPlaceStatus(place._id, "INACTIVE")}} data-tip="Rimuovi pubblicazione"><FontAwesomeIcon icon={faEyeSlash} /></button>}
                                    {pageState==="CHANGING_PLACE_STATUS" && placeChangingStatus===place._id && <button className="btn btn-secondary" disabled data-tip="Attendere prego..."><FontAwesomeIcon icon={faSpinner} spin /></button>}
                                </div>
                            </div>
                            <hr/>
                            </div>
                        )})
                        }
                        <ReactTooltip />
                    </div>
                }
            </div>
            <Modal show={!!newPlaceModalShow} onHide={()=>{return setNewPlaceModalShow(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuovo Luogo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label htmlFor="newPlaceName">Scegli un nome per il nuovo luogo:</label>
                        <input type="text" className="form-control" id="newPlaceName" value={newPlaceName} disabled={placeCreatingStatus==="CREATING"}  onChange={(e)=>{setNewPlaceName(e.target.value)}} />
                    </Modal.Body>
                    <Modal.Footer>
                    {placeCreatingStatus!=="CREATING" &&
                    <>
                    {placeCreatingStatus==="ERROR" && 
                        <span className="text-danger mr-2">Si è verificato un errore.</span>
                    }
                    <button  className="btn btn-secondary mt-3" onClick={()=>{setNewPlaceModalShow(false)}}>
                        Annulla
                    </button>
                    <button  className="btn btn-success mt-3" onClick={()=>{createPlace()}}>
                        Crea
                    </button>
                    </>
                    }
                    {placeCreatingStatus==="CREATING" &&
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                    }
                    </Modal.Footer>
            </Modal>
        </Container>
        </>
    )
}

export default Places