import { BrowserRouter as Router, Route } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import ProtectedRoute from 'src/Components/Common/ProtectedRoute'
import Home from 'src/Components/Home/Home'
import Login from 'src/Components/Auth/Login'
import Logout from 'src/Components/Auth/Logout'
import Users from 'src/Components/Users/Users'
import User from 'src/Components/Users/User'
import Places from 'src/Components/Places/Places'
import Place from 'src/Components/Places/Place'

import "bootstrap/dist/css/bootstrap.min.css"

function App() {
  return (
    <Router>
        <Container className="p-3 h-100">
          <ProtectedRoute path="/" exact component={Home} />
          <ProtectedRoute path="/logout" exact component={Logout} />
          <Route path="/login" component={Login} /> 
          <ProtectedRoute path="/users" exact component={Users} />
          <ProtectedRoute path="/users/:id" exact component={User} />
          <ProtectedRoute path="/places" exact component={Places} />
          <ProtectedRoute path="/places/new" exact component={Place} />
          <ProtectedRoute path="/places/edit/:id" exact component={Place} />
        </Container>
      </Router>
  );
}

export default App;
