import { Link } from "react-router-dom";

type HeaderPropsType = {
    currentPage: string
}
const Header = (props: HeaderPropsType)=>{
    const user = JSON.parse(localStorage.getItem("user") as string)

    const classActive = (linkName:string) =>{
        return (props.currentPage === linkName) ? 'nav-link active': 'nav-link'
    }

    return (<nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light align-middle shadow">
                <Link to="/" className="navbar-brand"><span className="text-dark h1">Chiavi della Cultura</span></Link>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto">
                        <li className="navbar-item">
                        <Link to="/users" className={classActive('Users')}>Utenti</Link>
                        </li>
                        <li className="navbar-item">
                        <Link to="/places" className={classActive('Places')}>Luoghi</Link>
                        </li>
                    </ul>
                </div>
                <div className="pull-right">
                    {user.firstname} {user.lastname} <a href="/logout">(logout)</a>
                </div>
            </nav>
            )
}

export default Header