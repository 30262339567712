import { faChurch, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from 'src/Components/Common/Header'

const Home = ()=> {
    
    return (
        <>
        <Header currentPage={"Home"} />
        <div className="d-flex justify-content-center">
            <div className="card text-white bg-primary sm-6" style={styles.card} onClick={()=>{return window.location.href = "/users"}}>
                <div className="card-body">
                    <h5 className="card-title d-flex"><FontAwesomeIcon icon={faUser} className="mr-2" /> Utenti</h5>
                    <p className="card-text">Ricerca un utente tramite indirizzo email. Qui puoi eventualmente disattivare l'utente risultante dalla ricerca.</p>
                </div>
            </div>
            <div className="card text-white bg-info sm-6" style={styles.card} onClick={()=>{return window.location.href = "/places"}}>
                <div className="card-body">
                    <h5 className="card-title d-flex"><FontAwesomeIcon icon={faChurch} className="mr-2" /> Luoghi</h5>
                    <p className="card-text">Crea, modifica e attiva i luoghi da visitare.</p>
                </div>
            </div>
        </div>
        </>
    )
}

const styles = {
    card: {
        width: '27rem',
        margin: 30,
        cursor: 'pointer'
    }
}

export default Home
