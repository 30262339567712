import { useState } from "react"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

type RichContentProps = {
    value?: string,
    onChange?: (value: string) => void,
    height?: number
}

const RichContent = (props: RichContentProps) => {
const { value, onChange, height=400 } = props
const [isHtmlShow, setIsHtmlShow] = useState(false)

    const showHtml = ()=>{
        setIsHtmlShow(prev=>!prev)

    }

    return (
        <div style={{position:'relative', overflowY: 'auto', height: `${height}px`}}>
            {!isHtmlShow &&
                <ReactQuill 
                theme="snow" 
                style={{height: `${height-50}px`}}
                value={value} 
                onChange={(v)=>{onChange?.(v)}} 
                modules={{
                    toolbar: {
                        container: [
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        ['link', 'image'],
                        ['clean']
                        ]
                }
                }}  
                />
            }
            {isHtmlShow &&
            <div style={{position: 'absolute', top: '40px', left: '5px', width: '98%', overflowY: 'auto', opacity: 1, backgroundColor: 'white'}}>
                <textarea value={value} style={{width: '100%', height: `${height-50}px`}} onChange={(e)=>{onChange?.(e.target.value)}} />
            </div>
            }
            <div style={{position: 'absolute', top: '5px', right: '5px'}}>
                <button type="button" className="form-control btn btn-sm btn-info" onClick={showHtml}>{`${!isHtmlShow?"Mostra HTML":"Nascondi HTML"}`}</button>
            </div>
        </div>
    )
}

export default RichContent