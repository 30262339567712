import Header from 'src/Components/Common/Header'

const User = ()=>{
    return (
        <>
        <Header currentPage={"Users"} />
        </>
    )
}

export default User