import {Redirect} from 'react-router-dom'

const Logout = () => {

    localStorage.removeItem("user")
    localStorage.removeItem("accessToken")

    const redirectProps = {
        to: {
            pathname: "/login"
        }
    }

    return <Redirect {...redirectProps} />
}

export default Logout