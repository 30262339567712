import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import env from '../env'

const FreeAPI = axios.create({
    baseURL: `${env.API_PROTOCOL}://${env.API_HOST}:${env.API_PORT}/api`
});

const ProtectedAPI = axios.create({
    baseURL: `${env.API_PROTOCOL}://${env.API_HOST}:${env.API_PORT}/api`,
    headers: {
        'cdc-environment-id': 'cdc-web'
    }
});


ProtectedAPI.interceptors.request.use(
    (request) => requestHandler(request)
);

const requestHandler = (request: AxiosRequestConfig):Promise<AxiosRequestConfig> =>{
    request.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
    return Promise.resolve(request)
}

const ProtectedResponseHandler = (response:AxiosResponse):AxiosResponse<any> | Promise<AxiosResponse<any>> =>{
    return Promise.resolve(response)
}

const ProtectedErrorHandler = (error:any)=>{
    if(error.response?.status===401) {
        localStorage.removeItem("accessToken")
        window.location.href='/logout'
    }
    return Promise.reject(error)
}

ProtectedAPI.interceptors.response.use(
    (response) => ProtectedResponseHandler(response),
    (error) => ProtectedErrorHandler(error)
 );

export {FreeAPI, ProtectedAPI}