import { useEffect, useState } from "react"
import { FreeAPI } from 'src/Api/Common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import getError from 'src/Utils/ErrorCodes'
import { useHistory } from "react-router-dom"


const Login = ()=> {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [rememberMe, setRememberMe] = useState(false)
    const [loginStatus, setLoginStatus] = useState("IDLE")
    const [loginError, setLoginError] = useState("")

    const history = useHistory()

    const doLogin = async ()=>{
        try {
            setLoginError("")
            setLoginStatus("SUBMITTING")
    
            const response = await FreeAPI.post('auth/login', {
                username,
                password
            })
    
            localStorage.setItem("accessToken", response.data.accessToken)
            localStorage.setItem("user", JSON.stringify(response.data.user))
            if(rememberMe) {
                localStorage.setItem("rememberUsername", username)
            } else {
                localStorage.removeItem("rememberUsername")
            }

            setLoginStatus("OK")
            return history.push("/")
        } catch(e:any) {
            setLoginStatus("ERROR")
            setLoginError(getError(e.response?.data?.errorCode))
        }
    }

    useEffect(()=>{
        const rememberUsername = localStorage.getItem("rememberUsername")
        if(!!rememberUsername) {
            setUsername(rememberUsername)
            setRememberMe(true)
        }
    },[])

    const submitting = (loginStatus==="SUBMITTING")
    const submitError = (loginStatus==="ERROR")
    return (
        <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-md-6 text-center align-self-center" style={style.formContainer}>
                    <form className="form-signin p-5 shadow">
                        <h1>Chiavi della cultura</h1>
                        <h4 className="mb-3 mt-4 font-weight-bold">Log in</h4>
                        <label htmlFor="inputEmail" className="sr-only">Email</label>
                        <input type="email" id="inputEmail" className="form-control mb-1" placeholder="Email" required autoFocus value={username} onChange={(e)=>setUsername(e.target.value)} disabled={submitting} /> 
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <input type="password" id="inputPassword" className="form-control" placeholder="Password" required value={password} onChange={(e)=>setPassword(e.target.value)} disabled={submitting} />
                        <div className="custom-control custom-checkbox my-3">
                            <input type="checkbox" checked={rememberMe} onChange={()=>setRememberMe(!rememberMe)} disabled={submitting} className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">Ricordami</label>
                        </div>
                        {submitting &&
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        }
                        {submitError &&
                            <h3 className="h6 mb-3 font-weight-bold text-danger">{loginError}</h3>
                        }
                        {!submitting &&
                            <button className="btn btn-lg btn-primary btn-block" type="button" onClick={doLogin}>Sign in</button>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

const style= {
    formContainer: {
        marginTop: 0,
        marginBottom: 0
    }
}

export default Login